import React, { useEffect, useState } from "react";
import Swiper from "swiper";
import * as styles from "./carouselPagination.module.scss";

interface Props {
  // list: any;
  swiper: Swiper | undefined;
  spacing: number;
}

export const CarouselPagination: React.FC<Props> = ({
  swiper,
  // list,
  spacing,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  swiper?.on("slideChange", () => setActiveIndex(swiper.activeIndex));

  return (
    <div className={styles.paginationContainer}>
      {swiper?.slides.map((_: any, index: number) => {
        return (
          <div
            key={index}
            className={`${styles.indicator} ${
              index === activeIndex && styles.indicatorActive
            }`}
            style={{ margin: `0 ${spacing / 2}px` }}
            onClick={() => swiper?.slideTo(index)}
          />
        );
      })}
    </div>
  );
};
