import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  SanitySnipcartVariant,
  StringQueryOperatorInput,
} from "../../../graphql-types";
import * as styles from "./addItemButton.module.scss";

interface Props {
  variant: SanitySnipcartVariant;
  slug: string;
  excerpt: string;
  title: string;
  tva?: number;
  quantity: number;
  image: string;
  onClick?: any;
  buttonTitle: string;
  setBuyBarVisible?: (inView: Boolean) => void;
}

export const AddItemButton: React.FC<Props> = React.memo(
  ({
    variant,
    slug,
    excerpt,
    title,
    tva = 0,
    quantity,
    image,
    onClick = () => {},
    buttonTitle = "Add to cart",
    setBuyBarVisible,
  }) => {
    // const price = parseInt(variant.price);
    const [ref, inView] = useInView({
      threshold: 1,
    });

    useEffect(() => {
      setBuyBarVisible && setBuyBarVisible(!inView);
    }, [inView]);

    return (
      <div className={styles.wrapper} onClick={onClick} ref={ref}>
        <button
          className="snipcart-add-item"
          data-item-has-taxes-included="true"
          data-item-name={`${title}`}
          data-item-id={variant?.sku}
          data-item-price={variant?.price?.toFixed(2)}
          data-item-url={`/products/${slug}`}
          data-item-description={excerpt}
          data-item-image={image}
          data-item-quantity={quantity}
          // disabled={true}
          // data-item-taxes={tva}
          // disabled={_stock === 0 ? true : false}
        >
          {buttonTitle}
        </button>
      </div>
    );
  }
);
