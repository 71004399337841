import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import * as styles from "./carousel.module.scss";
// import "swiper/swiper.min.css";
// import 'swiper/css/swiper.min.css'

import "swiper/swiper.scss";

interface Props {
  setSwiper: any;
  swiperContainerClass?: string;
  spaceBetween?: number;
  autoplay?: boolean;
}

export const Carousel: React.FC<Props> = ({
  children,
  setSwiper,
  swiperContainerClass,
  spaceBetween = 0,
  autoplay = false,
}) => (
  <div className={styles.wrapper}>
    <Swiper
      autoplay={autoplay}
      slidesPerView={1}
      direction="horizontal"
      spaceBetween={spaceBetween}
      onSwiper={(swiper) => setSwiper(swiper)}
      // onSlideChange={(e) => setActiveIndex(e.activeIndex)}
      centeredSlides={true}
      // initialSlide={0}
      className={swiperContainerClass || styles.swiperContainer}
      // breakpoints={{}}
      // centeredSlidesBounds={true}
    >
      {children}
    </Swiper>
  </div>
);
